<template>
	<b-row>
		<b-col>
			<horizontal-nav
			set_view
			:show_display="false"
			:items="items"></horizontal-nav>
		</b-col>
	</b-row>
</template>
<script>
export default {
	components: {
		HorizontalNav: () => import('@/common-vue/components/horizontal-nav/Index')
	},  
	computed: {
		items() { 
			return [
				{
					name: 'Estados de pedido',
					action: 'order_status/getModels',	
				},
				{
					name: 'Tipos de pedido',
					action: 'order_type/getModels',	
				},
				{
					name: 'Metodos de pago',
					action: 'payment_method/getModels',	
				},
			]
		}
	}
}
</script>